<template>
  <SwitchGroup>
    <SwitchLabel
      as="div"
      class="group space-x-2 flex text-sm text-gray-500 font-medium rounded"
      :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
    >
      <Switch
        :modelValue="modelValue"
        @update:modelValue="value => emit('update:modelValue', value)"
        :class="{
          'bg-primary group-hover:bg-primary-hover group-focus-within:bg-primary-hover':
            modelValue && !disabled,
          'bg-gray-300 group-hover:bg-gray-400 group-focus-within:bg-gray-400':
            !modelValue && !disabled,
          'bg-gray-300': modelValue && disabled,
          'bg-gray-200': !modelValue && disabled,
          'group-focus-within:ring-2 ring-offset-2 ring-primary': !disabled,
        }"
        :disabled="disabled"
        class="relative pointer-events-none inline-flex h-4 w-8 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
      >
        <span
          aria-hidden="true"
          :class="modelValue ? 'translate-x-4' : 'translate-x-0'"
          class="pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white transition duration-200 ease-in-out"
        />
      </Switch>
      <div class="w-full">
        <InputLabel v-if="hasLabel" :id="id || name" class="mb-1 pointer-events-none">
          <slot name="label">
            {{ label }}
          </slot>
        </InputLabel>
        <p v-if="hasDescription" class="block mt-1 font-normal">
          <slot name="description">
            {{ description }}
          </slot>
        </p>
        <slot></slot>
      </div>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script setup>
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue"
import InputLabel from "../inputs/_base/InputLabel.vue"
import { useSlots, computed } from "vue"

const props = defineProps({
  id: String,
  name: String,
  label: String,
  description: String,
  modelValue: Boolean,
  disabled: Boolean,
})
const emit = defineEmits(["update:modelValue"])

const slots = useSlots()
const hasLabel = computed(() => props.label || slots["label"])
const hasDescription = computed(() => props.description || slots["description"])
</script>
